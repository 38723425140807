<template>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-3 card']">
        <div>
            <v-container fluid>

                    <p><b>{{ $t('reports.lang_downloadStorageReport') }}:</b></p>
                    <p>- {{ $t('reports.lang_pleaseSelectReportAndPressDownload') }}<br>
                       - {{ $t('reports.lang_dependingOnNumberOfItemsInStock') }}</p>


                <v-layout>
                    <v-flex md6 mr-2 ml-2>
                        <v-select outlined
                                  :items="storageReportsSelect"
                                  item-text="name"
                                  item-value="id"
                                  v-model="storageReport"
                                  :label="$t('reports.storageReport')"
                        ></v-select>
                    </v-flex>

                    <v-flex md6 mr-2 ml-2>
                        <v-btn text color="error" @click="downloadReport">{{ $t('generic.lang_download') }}</v-btn>
                    </v-flex>
                </v-layout>

            </v-container>
        </div>
        <div id="onScreenKeyboardDiv">
        <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                            :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>



<script>
    import {ENDPOINTS} from '../../config'
    import {Events} from "../../plugins/events";
    import {mapState} from "vuex";
    import mixin from "../../mixins/KeyboardMixIns";

    export default {
        components: {
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                storageReportsSelect: [
                    {
                        name: this.$t('erp.lang_storageOverview'),
                        id:"storageOverview"
                    },
                    {
                        name: this.$t('reports.lang_storageValues'),
                        id:"storageValues"
                    }
                ],
                storageReport:"",
            }
        },
        computed: {
            ...mapState([
                'api'
            ])
        },
        methods: {

            downloadReport: function () {
              let apiServerIP = process.env.VUE_APP_API_HOST;
              if(window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
                apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
              }
                window.open(apiServerIP + "/get/reports/storage/" + this.storageReport + "/?&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");

            },
        },

        mounted() {

        },
    }
</script>